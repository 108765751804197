import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { faSearch, faUser, faHome, faStickyNote, faCogs, faPaperPlane, faArchway, faInfoCircle, faHandPeace, faHands,faBlog, faAddressBook } from '@fortawesome/free-solid-svg-icons';
declare var $: any; 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  faSearch = faSearch;
  faHome= faHome;
  faArchway=faArchway;
  faInfoCircle=faInfoCircle;
  faHandPeace=faHandPeace;
  faHands=faHands;
  faBlog=faBlog;
  faStickyNote=faStickyNote;
  faCogs=faCogs;
  faPaperPlane=faPaperPlane;
  faUser =faUser;
  faAddressBook=faAddressBook;
  menuOpened: boolean = false;
  bodydirection: boolean = false;
  current: number = 0;
  items: Array<any>;
  constructor(private translateService: TranslateService) {

    translateService.addLangs(['en', 'ar']);
    translateService.setDefaultLang('en');
    const browserLang = translateService.getBrowserLang();
    translateService.use(browserLang.match(/en|ar/) ? browserLang : 'en');
    
 
 
}
objectKeys = Object.keys;
my_menu = [
 {menu:[{main:'home', icon:this.faHome,  submenu: []}]},
 {menu:[{main:'aboutus', icon:this.faUser, submenu: []}]},
 {menu:[{main:'services', icon:this.faCogs,id:"collapseExample",submenu: [{sub:'products', icon:this.faHandPeace, route:'/about'}, {sub:'consultation',  icon:this.faHands,route:'about1'}]}]},
 {menu:[{main:'blog', icon:this.faBlog,submenu:[]}]},
 {menu:[{main:'contactus', icon:this.faPaperPlane,submenu:[]}]},
 
];

useLanguage(language) {
    if(language === "ar"){
 
    document.documentElement.setAttribute('lang', 'ar');
  }
  else {
    document.documentElement.setAttribute('lang', 'en');
  }
  this.translateService.use(language).toPromise();

 }
 
ngOnInit() {  
  this.current= 0;
  $('#sidebarCollapse').on('click', function () {
    $('#sidebar').toggleClass('active');
  });
}

}
