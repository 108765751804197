<div class="wrapper d-flex align-items-stretch">
  <nav id="sidebar" class="active">
    <h1><a href="/" class="logo">M.</a></h1>
   
    <ul class="list-unstyled components">
      <li  *ngFor="let mainItem of my_menu; let i = index" [class.active]='current === i' (click) = "current = i">
        <a *ngFor="let Item of mainItem.menu" data-toggle="collapse" href="#{{Item.id}}" role="button" aria-expanded="false" aria-controls="collapseExample"><fa-icon [icon]="Item.icon"></fa-icon> {{Item.main |translate}}</a>
        <ul *ngFor="let Item of mainItem.menu" [class.collapse]='current != i' class="collapse" id="collapseExample">
          <li *ngFor="let subItem of Item.submenu">
            
              <a class="ripple" href="" routerLink="subItem.route" [title]="subItem.route"><fa-icon [icon]="subItem.icon" class="mr-3"></fa-icon>{{ subItem.sub |translate }}</a>
   
          </li>
        </ul>
      </li>
       
    </ul>
     <div class="footer">
      <p>
        {{'copyright'|translate}} &copy;<script>document.write(new Date().getFullYear());</script>
      </p>
    </div>
  </nav>

    <!-- Page Content  -->
  <div id="content" class="p-4 p-md-5">

    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">

        <button type="button" id="sidebarCollapse" class="btn btn-primary">
          <i class="fa fa-bars"></i>
          <span class="sr-only">Menu</span>
        </button>
        <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-bars"></i>
        </button>
        <ul class="nav nav-pills">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle float-right ml-2" data-toggle="dropdown" href="#" role="button"
              aria-haspopup="true" aria-expanded="false">{{'language' | translate}}</a>
            <div class="dropdown-menu">
              <a class="dropdown-item" (click)="useLanguage('en')" href="#">En</a>
              <a class="dropdown-item" (click)="useLanguage('ar')" href="#">Ar</a>
            </div>
          </li>
        </ul>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
   
            <ul class="nav navbar-nav ml-auto">
              <li  *ngFor="let mainItem of my_menu; let i = index" class="nav-item" [class.active]='current === i'>
                <a *ngFor="let Item of mainItem.menu" href="#" class="nav-link" ><fa-icon [icon]="Item.icon"></fa-icon> {{Item.main |translate}}</a>
                <ul *ngFor="let Item of mainItem.menu">
                  <li *ngFor="let subItem of Item.submenu">
                    
                      <a href="" routerLink="subItem.route" [title]="subItem.route"><fa-icon [icon]="subItem.icon" class="mr-3"></fa-icon>{{ subItem.sub |translate }}</a>
           
                  </li>
                </ul>
              </li>
               
            </ul>
   
        </div>
      </div>
    </nav>

    <h2 class="mb-4">{{'muwaqil' | translate}}</h2>
    <p>{{'bodytext' | translate}}</p>
    <p>{{'bodytext2' | translate}}</p>
  </div>
</div>

 