import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSquare, faCheckSquare, faTachometerAlt,
  faSearch, faBars, faUser, faAngleRight, faAngleDown, faColumns, faBookOpen, faTable,
   faChartArea, faBroadcastTower, faBullhorn, faBroom, faHome, faStickyNote, faCogs, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faSquare as farSquare, faCheckSquare as farCheckSquare,  } from '@fortawesome/free-regular-svg-icons';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faSquare, faCheckSquare, faTachometerAlt, faSearch , faBars , faChartArea ,
       faUser , faAngleRight , faAngleDown , faColumns , faBookOpen , faTable , farSquare, farCheckSquare,
       faBroom,faBroadcastTower,faBullhorn, faHome, faStickyNote, faCogs, faPaperPlane);
  }
 }
